import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/login/LoginView')
  },
  {
    path: '/androidquery',
    component: () => import('@/views/query/queryView')
  },

  {
    path: '/home',
    component: () => import('@/views/home/HomeView'),
    redirect: '/welcome',
    children: [
      {
        path: '/welcome',
        component: () => import('@/views/welcome/WelcomeView'),
        meta: {
          requireAuth: true,
          nav: '欢迎页'
        }
      },
      {
        path: '/query',
        component: () => import('@/views/query/queryView')
      },
      {
        path: '/datauserView',
        component: () => import('@/views/datauser/DatauserView')
      },
      {
        path: '/ErrorView',
        component: () => import('@/views/base/ErrorView')
      },
      {
        path: '/BaseAndroid',
        component: () => import('@/views/base/BaseAndroid')
      },

    ]
  }
]

// 避免重复路由  new VueRouter前
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

// new VueRouter ***
const router = new VueRouter({
  routes
})

// 路由守卫(router/index.js)
router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    return next('/ErrorView');
  }
  if(to.path == '/androidquery'){
    return next();
  }else{
    if (to.path == '/login') {
      window.sessionStorage.removeItem('token');
      window.sessionStorage.removeItem('userlist');
      window.sessionStorage.removeItem('querylist');
      window.sessionStorage.removeItem('logintime');
      return next();
    } else {
      const token = window.sessionStorage.getItem('token');
      const logintem = window.sessionStorage.getItem('logintime');
      const ldt_dt = new Date().getTime() / 1000;

      if (!token ) {//|| ldt_dt - logintem > 9000
        window.sessionStorage.removeItem('token');
        window.sessionStorage.removeItem('userlist');
        window.sessionStorage.removeItem('querylist');
        window.sessionStorage.removeItem('logintime');
        return next('/login');
      } else {
        return next();
      }
    }
  }
})

export default router
